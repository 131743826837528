import React from "react"
import { graphql } from "gatsby"
import last from "lodash/last"
import queryString from "query-string"

import { Layout } from "@global"
import { SEO } from "@global"
import { ModuleItem } from "@elements"
import { useTestimonials, useGlobalModules } from "@hooks"

export default ({ data, location }) => {
  const { page } = data.wpgraphql

  const queryParams = queryString.parse(location.search)
  const testimonials = useTestimonials(5)
  const options = useGlobalModules()
  const metaImage = page.seo.opengraphImage
    ? page.seo.opengraphImage
    : data.thumbnail.childImageSharp.original
  const metaDescription =
    page.seo.opengraphDescription !== "" ? page.seo.opengraphDescription : null
  return (
    <Layout>
      <SEO title={page.title} image={metaImage} description={metaDescription} />
      <div className="relative">
        {page.pageFlexibleContent.pageLayout.map((module, idx) => {
          const moduleName = module && last(module.__typename.split("_"))
          return (
            <ModuleItem
              key={idx}
              type={moduleName}
              module={module}
              global={{ testimonials, options, queryParams }}
            />
          )
        })}
      </div>

      {process.env.NODE_ENV !== `production` &&
        console.log("Module Data: ", data)}
    </Layout>
  )
}

export const PageQuery = graphql`
  query PageQuery($id: ID!) {
    thumbnail: file(relativePath: { eq: "logos/logo-large.jpg" }) {
      childImageSharp {
        original {
          src
          height
          width
        }
      }
    }
    wpgraphql {
      page(id: $id) {
        id
        title
        slug
        pageFlexibleContent {
          ...PageModules
        }
        seo {
          opengraphDescription
          opengraphImage {
            sourceUrl
          }
        }
      }
    }
  }
`
